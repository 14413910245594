<template>
    <!-- auth-page wrapper -->
        <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div class="bg-overlay"></div>
            <!-- auth-page content -->
            <div class="auth-page-content overflow-hidden pt-lg-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card overflow-hidden">
                                <div class="row g-0">
                                    <div class="col-lg-6">
                                        <div class="p-lg-5 p-4 auth-one-bg h-100">
                                            <div class="bg-overlay"></div>
                                            <div class="position-relative h-100 d-flex flex-column">
                                                <div class="mb-4">
                                                    <router-link to="/" class="d-block">
                                                        <img src="@/assets/images/svg/easy_anest_branco.svg" alt="" height="18">
                                                    </router-link>
                                                </div>
                                                <div class="mt-auto">
                                                    <div class="mb-3">
                                                        <i class="ri-double-quotes-l display-4 text-success"></i>
                                                    </div>

                                                    <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                                                        <div class="carousel-indicators">
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="active"
                                                                aria-current="true" aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1"
                                                                aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2"
                                                                aria-label="Slide 3"></button>
                                                        </div>
                                                        <div class="carousel-inner text-center text-white-50 pb-5">
                                                          <div class="carousel-item active">
                                                            <p class="fs-15 fst-italic">" Otimize sua gestão pessoal e das suas equipes cirúrgicas. "</p>
                                                          </div>
                                                          <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">" Faturamento e documentos em segundos.."</p>
                                                          </div>
                                                          <div class="carousel-item">
                                                            <p class="fs-15 fst-italic">" Cansado de perder tempo e dinheiro gerindo seu faturamento? "</p>
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <!-- end carousel -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end col -->

                                    <div class="col-lg-6">
                                        <div class="p-lg-5 p-4">
                                            <div>
                                                <h5 class="text-primary">Bem vindo !</h5>
                                                <p class="text-muted">Faça login para continuar no  EASYANEST.</p>
                                            </div>
            
                                            <div class="mt-4">
                                              <form @submit.prevent="tryToLogIn">
                    
                                                    <div class="mb-3">
                                                        <label for="username" class="form-label">E-mail</label>
                                                        <input type="email" class="form-control" id="email"
                                                               v-model="email"
                                                               placeholder="Enter username">
                                                    </div>
                            
                                                    <div class="mb-3">
                                                        <div class="float-end">
                                                            <router-link to="/forgot-password" class="text-muted">Esqueçeu a senha ?</router-link>
                                                        </div>
                                                        <label class="form-label" for="password-input">Senha</label>
                                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                                            <input type="password" class="form-control pe-5"
                                                                   v-model="password"
                                                                   placeholder="Enter password" id="password-input">
                                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>
                            
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                                                        <label class="form-check-label" for="auth-remember-check">Lembrar</label>
                                                    </div>
                                                    <div class="mb-3 text-center">
                                                        <span ref="help_login"
                                                              v-html="help_login"
                                                              class="text-danger m-auto"></span>
                                                    </div>
                                                    <div class="mt-4">
                                                        <button class="btn btn-success w-100" type="submit" @click="signinapi"> Entrar</button>
                                                    </div>
                        
                                                    <div class="mt-4 text-center d-none">
                                                        <div class="signin-other-title">
                                                            <h5 class="fs-13 mb-4 title">

                                                              </h5>
                                                        </div>
        
                                                        <div>
                                                            <button type="button" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ri-facebook-fill fs-16"></i></button>
                                                            <button type="button" class="btn btn-danger btn-icon waves-effect waves-light ms-1"><i class="ri-google-fill fs-16"></i></button>
                                                            <button type="button" class="btn btn-dark btn-icon waves-effect waves-light ms-1"><i class="ri-github-fill fs-16"></i></button>
                                                            <button type="button" class="btn btn-info btn-icon waves-effect waves-light ms-1"><i class="ri-twitter-fill fs-16"></i></button>
                                                        </div>
                                                    </div>
                
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
    
                    </div>
                    <!-- end row -->
                </div>
                <!-- end container -->
            </div>
            <!-- end auth page content -->

            <!-- footer -->
            <footer class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center">
                                <p class="mb-0">&copy; {{new Date().getFullYear()}} EASY UX</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <!-- end Footer -->
        </div>
        <!-- end auth-page-wrapper -->
</template>

<script>

import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import appConfig from "../../../app.config";
import axios from 'axios';


export default {
  page: {
    title: "LoginCover",
    meta: [{
      name: "description",
      content: appConfig.description,
    }, ],
  },
  data() {
    return {
      help_login : "",
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      defaultOptions: {
      },
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email é obrigatório", required),
      email: helpers.withMessage("Informe um e-mail válido", email),
    },
    password: {
      required: helpers.withMessage("Senha é obrigatória", required),
    },
  },
  computed: {

  },
  methods: {
    async signinapi() {

      try {
        const result = await axios.post(process.env.VUE_APP_API + '/login', {
          email: this.email,
          password: this.password
        })

        if (result.data.status == 'errors') {
          return this.authError = result.data.data;
        }

        localStorage.setItem('jwt', result.data.access_token);
        localStorage.setItem('userdata', JSON.stringify(result.data.usuario))
        localStorage.setItem('userid', result.data.usuario.id)
        localStorage.setItem('user-Photo',result.data.usuario.Photo);
        localStorage.setItem('user-Perfil', result.data.usuario.TipoUsuarioId);

        return this.$router.push({
          path: '/'
        });

      }catch (e){
        this.help_login = e.response.data.error;
      }
    }
  },
}
</script>